import React from 'react'

import { NumericCard } from '@Components/numeric-card/NumericCard'
import { CommandsBar } from './CommandsBar'
import { formatByCurrency } from '@Utils'
import { useAsync } from 'react-async-hook'
import { fetchAgentSummaries, fetchAllMerchants } from '@Utils/Api'
import { useLocation } from 'react-router-dom'
import { Stack, Grid } from '@mui/material'
import { allCurrencies } from '../../Types'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { errorMessageAtom, formPayload } from '@RecoilGlobal/form'
import moment from 'moment'
import ErrorFieldMessage from '@Components/error-field-message/ErrorFieldMessage'
import { useResetOnMount, useStateChangeEffect } from '@Hooks'

const defaultValue = {
  currency: 1,
  merchantId: -1,
  startDate: moment().startOf('month').utc(true).toISOString()
} as const

const SummaryDisplay = ({ location }: { location: string }): JSX.Element => {
  const setErrorMessage = useSetRecoilState(errorMessageAtom(location))
  const { currency, merchantId, startDate } = useRecoilValue(formPayload({ key: location, defaultValue }))
  const currencyName = allCurrencies.find(v => v.id === currency)?.name ?? null

  const summaries = useAsync(async () => {
    return await fetchAgentSummaries({
      startDate: startDate != null ? moment(startDate as moment.Moment).startOf('month').utc(true).toISOString() : undefined,
      endDate: startDate != null ? moment(startDate as moment.Moment).endOf('month').utc(true).toISOString() : undefined,
      currency: currency as number,
      merchantId: merchantId as number
    })
  }, [startDate, currency, merchantId])

  useStateChangeEffect(() => {
    if (summaries.error != null) {
      setErrorMessage([summaries.error.message])
    } else {
      setErrorMessage(null)
    }
  }, [summaries])

  const { result: summary = { deposit: 0, withdraw: 0, fee: 0 } } = summaries

  return (
    <Stack>
      <Stack direction='row' spacing={2}>
        <NumericCard
          title='TOTAL DEPOSIT'
          loading={summaries.loading}
          value={summary.deposit}
          formatter={formatByCurrency(currencyName)}
        />
        <NumericCard
          title='TOTAL WITHDRAWAL'
          loading={summaries.loading}
          value={summary.withdraw}
          formatter={formatByCurrency(currencyName)}
        />
        <NumericCard
          title='TOTAL FEE / MONTH'
          loading={summaries.loading}
          value={summary.fee}
          formatter={formatByCurrency(currencyName)}
        />
      </Stack>
    </Stack>
  )
}
const Summary = (): JSX.Element => {
  const { pathname: location } = useLocation()
  useResetOnMount(formPayload({ key: location, defaultValue }))
  const getMerchants = useAsync(fetchAllMerchants, [])
  const merchants = getMerchants.loading
    ? []
    : (getMerchants.result != null)
        ? getMerchants.result.items
        : []

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ErrorFieldMessage location={location} />
      </Grid>
      <Grid item xs={12}>
        <CommandsBar location={location} merchants={merchants} />
      </Grid>
      <Grid item xs={12}>
        <SummaryDisplay location={location} />
      </Grid>
    </Grid>
  )
}

export default Summary
